import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Logo from '../assets/media/digitalharshal-logo.svg'

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const toggleMobileMenu = () => { setMenuOpen(!menuOpen) };
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolling) {
        setScrolling(isScrolled);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolling]);
  const headerClass = scrolling ? 'scrolled' : '';

  return (
    <header id="mastheadHome" className={headerClass}>
      <div className='container'>
        <div className="header-row h-16">
          <div className="logo-nav">
            <Link to="/" className="text-black fw-bold logo">
              <img src={Logo} alt='Digital Harshal'/>
            </Link>
            {/* <span className="alert alert-success rounded-pill px-3 py-2 m-0 ms-3"> Available for Work</span> */}
            <ul className={`topnav ${menuOpen ? 'mobile-hidden' : ''}`}>
                {/* <li className='active'><Link to="/" className="position-relative ">Home</Link></li>
                <li><Link to="/about" className="position-relative ">About</Link></li>
                <li><Link to="/services" className="position-relative ">Services</Link></li> */}
                {/* <li><Link to="/contact" className="position-relative ">Contact</Link></li> */}
                {/* <li><Link to="/contact" className="consulting-lia desk-none fw-semibold position-relative ">Contact</Link></li> */}
            </ul>
            {/* <div className={`burger-menu ${menuOpen ? 'active' : ''}`} onClick={toggleMobileMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div> */}
          </div>
          <ul className="useoptions">
            <li className="login"><Link to="/contact" className="consulting-lia text-uppercase fw-bold">Contact</Link></li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header