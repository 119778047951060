import { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer"
import Header from "../Components/Header"
import axios from 'axios';
import contactdigitalharshal from '../assets/media/contact-digitalharshal.png'

function Contact() {

  return (
    <>
        <Header/>

        <section className="contactPage">
        <div className="container">
          <div className="row pt-5">
            {/* <div className="col-lg-12">
              <h1 className="display-2 fw-semibold mb-3 lh-2"><span className="d-block fs-5 text-uppercase text-logo">Contact Us</span>How May I Help You!</h1>
            </div> */}

            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12">
                  <h1 className="display-1 fw-bold mb-5 lh-1"><span className="d-block fs-5 text-uppercase text-logo">Contact</span>Let's Talk</h1>
                </div>
                <div className="col-lg-6">
                  <div className="bg-white rounded-3  contact-info1">
                    <span className="d-block fs-5 fw-bold">Call Now</span>
                    <Link to="tel:7040617823" className="fs-6 text-slate-700"> +91 7040617823 </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="bg-white rounded-3  contact-info1">
                    <span className="d-block fs-5 fw-bold">Email</span>
                    <a href="mailto:contact@digitalharshal.com" className="fs-6 text-slate-700"> contact@digitalharshal.com</a>
                  </div>
                </div>
                <div className="lg:col-span-12">
                  <div className="bg-white rounded-3 border contact-info2">
                    <span className="d-block fs-5 fw-bold">Address</span>
                    <address className="fs-6 text-slate-700 mb-0">Panchavati, Nashik, Maharashtra, India 422003</address>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <img src={contactdigitalharshal} alt='Contact Digital Harshal' className="img-fluid mt-2"/>
            </div>

          </div>
        </div>
      </section>

        <Footer/>
    </>
  )
}

export default Contact