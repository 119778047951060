import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import StickySection from '../Components/StickySection'
import Logo from '../assets/media/profile-photo.png'

function Home() {
  return (
    <>
    <Helmet>
      <title>Freelancer | Website Design, Digital Marketing, App Development & Consulting | Digital Harshal</title>
      <meta name="description" content="Discover top-notch freelance services including website design & development, digital marketing, app development, and consulting. Partner with me at Digital Harshal to transform your digital presence and achieve your business goals. Contact now for tailored solutions!" />
    </Helmet>
    <Header/>

    <section className="hero">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            {/* <img src={Logo} alt='Digital Harshal' className="img-fluid"/> */}
          </div>
          <div className="col-lg-12">
            {/* <h2 className="display-1 fw-semibold lh-1 mb-3">Your Online <br></br>Solutions Expert</h2> */}
            <h1 className='display-5 text-dark fw-semibold'>If you're searching for a <span className="yellow-text">Website designer &amp; Developer</span> to transform your ideas into reality</h1>
            <Link to="/contact" className="btn yellow-bg yellow-btn rounded-pill d-inline-block px-5 py-3 mt-2 fs-6 fw-semibold">Let's Work Together!</Link>
          </div>
        </div>
      </div>
    </section>

    <StickySection/>

    <section className="quick">
      <div className="container">
        <h2 className="display-6 fw-medium">Want to build a credible and relatable Website <br></br>for your target audience?</h2>
        <Link to="/contact" className="btn yellow-white rounded-pill d-inline-block px-5 py-3 mt-2 fs-6 fw-bold">Let's Work Together!</Link>
      </div>
    </section>

    <Footer/>
    </>
  )
}

export default Home