import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Contact from './pages/Contact'

function PagesRoutes() {
  return (
    <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route path='/contact' element={<Contact/>}></Route>
        
        {/* <Route path="*" element={<RestrictedContent />} /> */}

        {/* <Route path='/terms-of-use' element={<Terms/>}></Route> */}
        
        {/* Services */}
        {/* <Route path='/services' element={<Services />}></Route> */}
    </Routes>
  )
}

export default PagesRoutes